<template>
  <v-container fluid>
    <div v-if="minitData">
      <v-stepper
        v-if="minitData.deadline == 'open'"
        alt-labels
        class="elevation-0 transparent"
        v-model="step"
      >
        <v-stepper-header class="elevation-0 mb-3">
          <v-stepper-step step="1" style="font-size: 0.8rem"
            >Personal Details</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="2" style="font-size: 0.8rem"
            >Publication Details</v-stepper-step
          >
          <!-- <v-divider></v-divider>
          <v-stepper-step step="3" style="font-size: 0.8rem"
            >Job Details</v-stepper-step
          > -->


          <!-- <v-divider></v-divider>
          <v-stepper-step step="3" style="font-size: 0.8rem"
            >Additional Details</v-stepper-step
          > -->

          <!-- <v-divider></v-divider>
          <v-stepper-step step="3" style="font-size: 0.8rem"
            >Additional Details</v-stepper-step
          > -->
          <v-divider></v-divider>
          <v-stepper-step step="4" style="font-size: 0.8rem"
            >Important Information</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items
          class="pt-3 white rounded-2"
          style="
            min-height: 350px;
            max-width: 1100px;
            margin: auto;
            border-radius: 10px;
          "
        >
          <v-stepper-content step="1" class="white pa-2">
            <v-form ref="formStep1" v-model="formStep1">
              <div class="cardHeader">Personal Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="First Name *"
                      :rules="[rules.req]"
                      v-model="appData.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Last Name"
                      v-model="appData.lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Father/Guardian Name"
                      v-model="appData.fatherName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="appData.gender"
                      hide-details
                      outlined
                      dense
                      label="Gender *"
                      :items="['Male', 'Female', 'Others']"
                      :rules="[rules.req]"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="appData.dob"
                          outlined
                          dense
                          hide-details
                          label="DOB *"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.req]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="appData.dob"
                        :max="dob_max"
                        min="1900-01-01"
                        @change="savedob"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="appData.nationality"
                      hide-details
                      outlined
                      dense
                      label="Nationality *"
                      :items="nationality"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="appData.idProofType"
                      hide-details
                      outlined
                      dense
                      label="ID Proof Type *"
                      :items="['Emirates ID', 'Passport']"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="appData.idProofNumber"
                      hide-details
                      outlined
                      dense
                      label="ID Proof Number *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    v-if="appData.idProofType == 'Passport'"
                  >
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="appData.passportExpiryDate"
                          outlined
                          dense
                          hide-details
                          label="Expiry Date *"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.req]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker1"
                        v-model="appData.passportExpiryDate"
                        min="2023-01-01"
                        max="2033-12-31"
                        @change="saveexpirydate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>

              <div class="cardHeader">Address Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Line 1 *"
                      :rules="[rules.req]"
                      v-model="appData.addLine1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Line 2"
                      v-model="appData.addLine2"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.city"
                      hide-details
                      outlined
                      dense
                      label="Town/City *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.state"
                      hide-details
                      outlined
                      dense
                      label="Parish/State/County *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="appData.country"
                      hide-details
                      outlined
                      dense
                      label="Country *"
                      :items="countries"
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.zipCode"
                      hide-details
                      outlined
                      dense
                      maxlength="10"
                      label="Postal/Zip Code *"
                      :rules="[rules.req]"
                      @keypress="filterCharNum(event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <div class="cardHeader">Contact Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      autocomplete="chrome-off"
                      type="text"
                      v-model="appData.ext"
                      hide-details
                      outlined
                      dense
                      label="Country Code *"
                      :items="countriescode"
                      item-text="code"
                      item-value="dial_code"
                      :rules="[rules.req]"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.mobile"
                      hide-details
                      outlined
                      dense
                      maxlength="15"
                      label="Mobile *"
                      :rules="Vmobilenumber"
                      @keypress="filterNum(event)"
                    ></v-text-field>
                    <!-- <vue-tel-input-vuetify
                      v-model="appData.mobile"
                      outlined
                      dense
                      label="Mobile *"
                      :rules="[rules.req]"
                    ></vue-tel-input-vuetify> -->
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-text-field
                      v-model="appData.email"
                      hide-details
                      outlined
                      dense
                      label="Email Id *"
                      :rules="[rules.req, rules.email]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- <div class="cardHeader">Covid-19 Vaccination Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="12">
                    <div  style="font-size:1.1rem;">Have you been vaccinated? *</div>
                  <v-radio-group
                    class="mt-3"
                    v-model="appData.isVaccinated"
                    hide-details
                    label=""
                   
                    :rules="[rules.req]"
                  >
                      <v-radio label="First Dosage" value="First Dosage"></v-radio>
                    <v-radio label="Fully Vaccinated" value="Fully Vaccinated"></v-radio>
                    <v-radio label="Not Vaccinated" value="Not Vaccinated"></v-radio>
                  </v-radio-group>
                </v-col>
                </v-row>
              </v-card> -->

              <div class="cardHeader">Upload Personal Documents</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="4">
                    <div class="text-center">Headshot *</div>
                    <div class="text-center">
                      Should be in JPEG/JPG/PNG Format, Max File size 1 MB
                    </div>
                    <div
                      style="
                        width: 100%;
                        height: 180px;
                        text-align: center;
                        padding-top: 15px;
                      "
                    >
                      <img
                        style="
                          border: 1px solid #ccc !important;
                          border-radius: 8px !important;
                          max-height: 140px !important;
                        "
                        v-if="$store.state.imgHeadShot != ''"
                        :src="$store.state.imgHeadShot"
                      />
                      <v-icon v-else style="font-size: 11rem" color="#ececec"
                        >mdi-account-box-outline</v-icon
                      >
                    </div>
                    <v-card flat>
                      <Headshot></Headshot>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4" style="border-left: 1px solid #ccc">
                    <div class="text-center">ID - Front Photo *</div>
                    <div
                      style="
                        width: 100%;
                        min-height: 180px;
                        text-align: center;
                        padding-top: 15px;
                      "
                    >
                      <img
                        style="
                          border: 1px solid #ccc !important;
                          border-radius: 8px !important;
                          max-height: 140px !important;
                        "
                        v-if="$store.state.imgId != ''"
                        :src="$store.state.imgId"
                      />
                      <v-icon v-else style="font-size: 10rem" color="#ececec"
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                    <v-card flat>
                      <IdCard></IdCard>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4">
                    <div class="text-center">ID - Back Photo</div>
                    <div
                      style="
                        width: 100%;
                        min-height: 180px;
                        text-align: center;
                        padding-top: 15px;
                      "
                    >
                      <img
                        style="
                          border: 1px solid #ccc;
                          border-radius: 8px;
                          max-height: 140px;
                        "
                        v-if="$store.state.imgIdBack != ''"
                        :src="$store.state.imgIdBack"
                      />
                      <v-icon v-else style="font-size: 10rem" color="#ececec"
                        >mdi-card-bulleted-outline</v-icon
                      >
                    </div>
                    <v-card flat>
                      <IdCardBack></IdCardBack>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat outlined class="pa-4">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    :disabled="!formStep1"
                    color="success"
                    @click="proceed1"
                    :loading="stepLoading"
                    >Proceed</v-btn
                  >
                  <!--  -->
                </v-card-actions>
              </v-card>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2" class="white pa-2">
            <v-form ref="formStep2" v-model="formStep2">
              <div class="cardHeader">Publication Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      dense
                      counter
                      maxlength="25"
                      v-model="appData.mediaName"
                      label="Name of Media House *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="appData.mwdiaWebsite"
                      label="Publication Website *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      label="Media Category *"
                      outlined
                      dense
                      hide-details
                      v-model="selMediaCategory"
                      :items="mediaTypeList"
                      item-text="mc_name"
                      item-value="mc_id"
                      @change="changeCategory"
                      :rules="[rules.req]"
                      return-object
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      label="Type of Organisation *"
                      outlined
                      dense
                      hide-details
                      v-model="appData.organisationType"
                      :items="subCategoryList"
                      item-text="msc_name"
                      item-value="msc_id"
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card>

              <div class="cardHeader">Job Details</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      label="Please select your role for the Event *"
                      outlined
                      dense
                      hide-details
                      v-model="appData.roleForEvent"
                      :items="mediaRole"
                      item-text="role_name"
                      item-value="role_id"
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    v-if="this.minitData.venue_type == 'Central'"
                  >
                    <v-select
                      label="Please select prefered city to collect accreditation card? *"
                      outlined
                      dense
                      hide-details
                      v-model="appData.preferedCity"
                      :items="preferedCityList"
                      item-text="venue_name"
                      item-value="venue_id"
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card>

              <div class="cardHeader">Publication Address</div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Line 1 *"
                      :rules="[rules.req]"
                      v-model="appData.pAddLine1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Line 2"
                      v-model="appData.pAddLine2"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.pCity"
                      hide-details
                      outlined
                      dense
                      label="Town/City *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.pState"
                      hide-details
                      outlined
                      dense
                      label="Parish/State/County *"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="appData.pCountry"
                      hide-details
                      outlined
                      dense
                      label="Country *"
                      :items="countries"
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="appData.pZipCode"
                      hide-details
                      outlined
                      dense
                      maxlength="10"
                      label="Postal/Zip Code *"
                      :rules="[rules.req]"
                      @keypress="filterCharNum(event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <div class="cardHeader">Authorisation Details</div>
              <div class="pa-2 pt-4" style="font-size: 1rem">
                Please be aware that you cannot be the assigning editor for your
                own application. You must provide details of your employer or
                editor in this section.
              </div>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Name *"
                      :rules="[rules.req]"
                      v-model="appData.authName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      label="Role *"
                      v-model="appData.authRole"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col cols="12" md="6">
                    <vue-tel-input-vuetify
                      v-model="appData.authMobile"
                      outlined
                      dense
                      label="Mobile *"
                      :rules="[rules.req]"
                    ></vue-tel-input-vuetify>


                  </v-col> -->

                  <v-col cols="12" md="2">
                    <v-autocomplete
                      autocomplete="chrome-off"
                      v-model="appData.authMobExt"
                      hide-details
                      outlined
                      dense
                      label="Country Code *"
                      :items="countriescode"
                      item-text="code"
                      item-value="dial_code"
                      :rules="[rules.req]"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="appData.authMobile"
                      hide-details
                      outlined
                      dense
                      maxlength="15"
                      label="Mobile *"
                      :rules="Vmobilenumber"
                      @keypress="filterNum(event)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="appData.authEmail"
                      hide-details
                      outlined
                      dense
                      label="Email Id *"
                      :rules="[rules.req, rules.email]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="pt-2 pb-3">
                      Please upload an Authorised letter from your assigning
                      editor.
                    </div>
                    <v-file-input
                      label="Choose File *"
                      ref="fileLetter"
                      outlined
                      show-size
                      dense
                      accept=".png, .jpeg, .jpg, .pdf"
                      hint="File type should be in PDF/JPG/JPEG/PNG"
                      persistent-hint
                      :rules="[rules.req]"
                      @change="selectFileLetter"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat outlined class="pa-4">
                <v-card-actions>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    outlined
                    color="primary"
                    @click="gobackSection(1)"
                    >Previous</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    :disabled="!formStep2"
                    color="success"
                    @click="proccedPublication"
                    >Proceed</v-btn
                  > </v-card-actions
                ><!--  -->
              </v-card>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3" class="white pa-2">
            <v-form ref="formStep4" v-model="formStep4">
              <div class="cardHeader">Travel Details</div>
              <v-card flat class="pa-4 mt-2">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group
                      v-model="appData.fromHostNation"
                      label="Are you presently available at the host nation? *"
                      class="mt-0"
                      :rules="[rules.req]"
                    >
                      <v-radio label="Yes" value="Yes"></v-radio>
                      <v-radio label="No" value="No"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div v-if="appData.fromHostNation == 'No'">
                <v-card flat outlined class="pa-4 mt-2 mb-2">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departPort"
                        hide-details
                        outlined
                        dense
                        label="Departure Port"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departDate"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Departure Date"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departTime"
                        hide-details
                        outlined
                        dense
                        type="time"
                        label="Departure Time"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departFlight"
                        hide-details
                        outlined
                        dense
                        label="Departure Flight"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card flat outlined class="pa-4 mt-2 mb-2">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arrivePort"
                        hide-details
                        outlined
                        dense
                        label="Arriving Port"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveDate"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Arriving Date"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveTime"
                        hide-details
                        outlined
                        dense
                        type="time"
                        label="Arriving Time"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveFlight"
                        hide-details
                        outlined
                        dense
                        label="Arriving Flight"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="cardHeader">Visa Details</div>
                <v-card flat class="pa-4">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="appData.visaNo"
                        hide-details
                        outlined
                        dense
                        label="Visa Number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="appData.visaValidTill"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Visa Valid Till"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="cardHeader">Accommodation Details</div>
                <v-card flat class="pa-4">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        label="Line 1"
                        v-model="appData.accAddLine1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        label="Line 2"
                        v-model="appData.accAddLine2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accCity"
                        hide-details
                        outlined
                        dense
                        label="City"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accState"
                        hide-details
                        outlined
                        dense
                        label="Parish/State/County"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="appData.accCountry"
                        hide-details
                        outlined
                        dense
                        label="Country"
                        :items="countries"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accZipCode"
                        hide-details
                        outlined
                        dense
                        label="Postal/Zip Code"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div class="cardHeader">Covid 19 Assesment</div>
              <v-row class="ma-0">
                <v-col cols="12" md="12">
                  <v-radio-group
                    class="mt-0"
                    hide-details
                    v-model="appData.anyMedicalCondition"
                    :rules="[rules.req]"
                    label="Do you have any pre-existing medical condition?"
                  >
                    <v-radio label="Yes" value="Yes"></v-radio>
                    <v-radio label="No" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="appData.anyMedicalCondition == 'Yes'"
                >
                  <!-- <v-text-field
                    hide-details
                    outlined
                    dense
                    v-model="appData.medicalCondition"
                    label="Please specify medical condition *"
                    :rules="[rules.req]"
                    placeholder="Fever / Dry cough / Tiredness / Loss of taste or smell / Sore throat"
                  ></v-text-field> -->

                  <v-select
                    v-model="aclmc"
                    hide-details
                    outlined
                    chips
                    multiple
                    deletable-chips
                    label="Please specify medical condition *"
                    :items="[
                      'Fever',
                      'Dry cough',
                      'Tiredness',
                      'Loss of taste or smell',
                      'Sore throat',
                      'Asymptomatic',
                    ]"
                    :rules="[rules.reqArr]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" md="12">
                  <v-radio-group
                    class="mt-0"
                    v-model="appData.isVaccinated"
                    hide-details
                    label="Have you been vaccinated? *"
                    :rules="[rules.req]"
                  >
                    <v-radio label="First Dose" value="First Dose"></v-radio>
                    <v-radio label="Second Dose" value="Second Dose"></v-radio>
                    <v-radio
                      label="Booster Dose"
                      value="Booster Dose"
                    ></v-radio>
                    <v-radio label="No" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <div
                v-if="
                  appData.isVaccinated == 'First Dose' ||
                  appData.isVaccinated == 'Second Dose' ||
                  appData.isVaccinated == 'Booster Dose'
                "
              >
                <v-row class="ma-0">
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      dense
                      label="Vaccination taken *"
                      hide-details
                      :items="vacineList"
                      v-model="appData.vaccineTaken"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      dense
                      hide-details
                      label="Country of issue *"
                      :items="countries"
                      v-model="appData.vaccineCountry"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      label="Vaccinated on *"
                      type="date"
                      hide-details
                      v-model="appData.firstDose"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <!-- <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="appData.secondDose"
                      label="Date of second dose"
                      type="date"
                      hide-details
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="appData.boosterDose"
                      label="Date of Buster dose"
                      type="date"
                      hide-details
                    ></v-text-field>
                  </v-col> -->
                </v-row>

                <v-card flat class="pa-4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="pt-2 pb-3">
                        Please upload Final Vaccination Certificate.
                      </div>
                      <v-file-input
                        label="Choose File *"
                        ref="fileLetter"
                        outlined
                        show-size
                        dense
                        accept=".pdf,.png,.jpg,.jpeg"
                        hint="File type should be in PDF,PNG,JPG,JPEG"
                        persistent-hint
                        :rules="[rules.req]"
                        @change="selectFileVaccination"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <v-card flat outlined class="pa-4">
                <v-card-actions>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    outlined
                    color="primary"
                    @click="gobackSection(2)"
                    >Previous</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    color="success"
                    @click="proccedAddi"
                    >Proceed</v-btn
                  > </v-card-actions
                ><!--  -->
              </v-card>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="4" class="white pa-2">
            <v-form ref="formStep5" v-model="formStep5">
              <div class="cardHeader">Important Information</div>
              <v-row>
                <v-col cols="12" md="8">
                  <v-card flat class="pa-4">
                    <ul>
                      <li>
                       Your accreditation card will be available <u><b>only</b></u> at the venue you have selected as your preferred and can <u><b>only</b></u> be collected by the named individual/s
                      </li>
                      <li>
                       Accreditation centres opening dates and times will be advised upon the approval of your accreditation. 
                      </li>
                      <li>
                       Accreditation is valid for all three venues for all matches including the Playoffs and Final.
                      </li>
                      <li>
                        Approval of the Accreditation is at the discretion of the league. Only bonafide members of the <u><b>working media</b></u> are allowed in the press areas and these should not be considered <u><b>to watch matches as spectators.</b></u>
                      </li>
                      <li>
                        Videography and live capture of any match is <u><b>not permitted</b></u> inside any venue - this is restricted to the authorised Global Broadcast Partner ZEE.
                      </li>
                      <li>
                       Please verify with the Media Accreditation team PRIOR ARRIVAL into the UAE of equipment being carried into the UAE (i.e. Carnet List) to assist with prompt entry into the UAE. You may reach ILT20 Media Accreditation via {{ minitData.media_manager_email }}.
                      </li>
                    </ul>
                  </v-card>

                  <v-card flat class="pa-4">
                    
                        <v-card flat class="mt-3">
                          <div class="cardHeader">Schedule</div>
                          <div class="pa-3">
                            <v-card flat class="mt-6">
                              <div class="text-center pa-2">
                                <v-btn
                                  outlined
                                  color="primary"
                                  target="blank"
                                  @click="btnviewschedule"
                                  >View Schedule
                                  <v-icon right
                                    >mdi-book-search-outline</v-icon
                                  ></v-btn
                                >
                              </div>
                            </v-card>
                          </div>
                        </v-card>
                     
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <!-- :firstName="appData.firstName"
                    :lastName="appData.lastName"
                    :mediaName="appData.mediaName"
                    :zone="zone"
                     :designation="appData.role_name" -->

                  <CardPreview
                    :firstName="appData.firstName"
                    :lastName="appData.lastName"
                    :mediaName="appData.mediaName"
                    :designation="selRole.role_name"
                    :zone="zone"
                  ></CardPreview>
                </v-col>
              </v-row>
              
              <v-card flat outlined class="pa-4">
                <v-checkbox
                v-model="chkverify"
                label="League has the right to cancel my accreditation card, If i fail to collect my accreditation card within 3 weeks from start of the league."
              ></v-checkbox>
                <v-card-actions>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    outlined
                    color="primary"
                    @click="gobackSection(2)"
                    >Previous</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    color="success"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="appSubmit"
                    >SUBMIT</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="5" class="white pa-2">
            <v-form ref="formStep3" v-model="formStep3">
              <div class="cardHeader">Job Details</div>

              <v-card flat class="pa-4">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      label="Please select your role below for the Event"
                      outlined
                      chips
                      deletable-chips
                      hide-details
                      :rules="[rules.req]"
                      v-model="appData.roleForEvent"
                      :items="mediaRole"
                      item-text="role_name"
                      item-value="role_id"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      label="Please select prefered city to collect accreditation card"
                      outlined
                      chips
                      deletable-chips
                      hide-details
                      :rules="[rules.req]"
                      v-model="appData.preferedCity"
                      :items="preferedCityList"
                      item-text="venue_name"
                      item-value="venue_id"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-select
                      label="Select the match you would like to cover in ILT20 *"
                      outlined
                      chips
                      deletable-chips
                      hide-details
                      multiple
                      v-model="appData.selectedMatchs"
                      :rules="[rules.reqArr]"
                      :items="currentEvents"
                      item-disabled="md_flag"
                      item-text="match_name"
                      item-value="s_id"
                      return-object
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card>

              <v-card flat outlined class="pa-4">
                <v-card-actions>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    outlined
                    color="primary"
                    @click="gobackSection(4)"
                    >Previous</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    large
                    class="pl-5 pr-5"
                    :disabled="!formStep3"
                    color="success"
                    @click="proceedJob"
                    >Proceed</v-btn
                  > </v-card-actions
                ><!--  -->
              </v-card>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog
        v-model="tncdialog"
        scrollable
        persistent
        max-width="900px"
        v-if="minitData.deadline == 'open'"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-text>
            <div v-html="tchtml"></div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="tncdialog = false">
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert v-else class="ma-2 mt-10" type="error">
        Application Closed
      </v-alert>
      <v-snackbar
        top
        centered
        v-model="snackbar.display"
        :color="snackbar.color"
        >{{ snackbar.text }}</v-snackbar
      >
      <v-overlay :value="isLoading" style="z-index: 9999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <div v-else>
      <div
        class="pt-10 mt-10 text-center"
        style="max-width: 400px; margin: auto"
      >
        Loading..
        <v-progress-linear
          class="mt-4"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </div>
    </div>

    <v-dialog max-width="800" scrollable v-model="dialogpdf">
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">Schedule</div>
          <v-spacer></v-spacer>
          <v-btn right icon color="red" @click="dialogpdf = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <pdf :src="schedulepath" :key="schedulepath"> </pdf>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import country from "../../../data/country.json";
import countrycode from "../../../data/countryCode.json";
import nationality from "../../../data/nationality.json";
import Headshot from "../../HeadShot";
import IdCard from "../../IdCard";
import IdCardBack from "../../IdCardBack";

import CardPreview from "../../CardPreview";
import pdf from "vue-pdf";

export default {
  components: { Headshot, IdCard, IdCardBack, CardPreview, pdf },
  data: () => ({
    step:1,
    chkverify: false,
    minitData: "",
    schedulepath: "",
    dialogpdf: false,
    stepLoading: false,
    isLoading: false,
    tncdialog: true,
    snackbar: { display: false, text: "", color: "primary" },
    headShotMode: "",
    formStep1: false,
    formStep2: false,
    formStep3: false,
    formStep4: false,
    formStep5: false,
    menu: false,
    menu1: false,
    dob_max: "",
    expiry_max: "",
    tchtml: "",
    appData: {
      event_id: "",
      venue_id: "",
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      nationality: "",
      idProofType: "",
      idProofNumber: "",
      passportExpiryDate: "",
      addLine1: "",
      addLine2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      mobile: "",
      email: "",
      photo: "",
      photoIdFront: "",
      photoIDBack: "",
      mediaName: "",
      mwdiaWebsite: "",
      mediaType: "",
      mediaType_name: "",
      organisationType: "",
      pAddLine1: "",
      pAddLine2: "",
      pCity: "",
      pState: "",
      pCountry: "",
      pZipCode: "",
      authName: "",
      authRole: "",
      authMobile: "",
      authEmail: "",
      isPastEvent: "No",
      pastEvents: [],
      pastOthers: "",
      roleForEvent: "",
      jobtype_id: 1,
      preferedCity: "",
      selectedMatch: [],
      fromHostNation: "Yes",
      departPort: "",
      departDate: "",
      departTime: "",
      departFlight: "",
      arrivePort: "",
      arriveDate: "",
      arriveTime: "",
      arriveFlight: "",
      visaNo: "",
      visaValidTill: "",
      accAddLine1: "",
      accAddLine2: "",
      accCity: "",
      accState: "",
      accCountry: "",
      accZipCode: "",
      anyMedicalCondition: "",
      medicalCondition: "",
      isVaccinated: "",
      vaccineTaken: "",
      vaccineCountry: "",
      firstDose: "",
      secondDose: "",
      boosterDose: "",
      ext: "",
      authMobExt: "",
      fatherName: "",
    },
    zone: "",
    selRole: {},
    mediaTypeList: [],
    subCategoryList: [],

    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
      reqwebsite: (val) =>
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
          val
        ) || "Website must be valid",
    },

    Vmobilenumber: [
      (v) => !!v || "Required",
      (v) =>
        (!isNaN(parseFloat(v)) && v >= 1 && v <= 99999999999999) ||
        "15 Number Only",
    ],

    countries: [],
    countriescode: [],
    nationality: [],
    pastEvents: [],
    currentEvents: [],
    preferedCityList: [],
    mediaRole: [],
    mediajobtype: [],
    selMediaCategory: {},
    iccPastFlag: "",
    currentFile: "",
    currentFileVaccination: "",
    vacineList: [
      "Pfizer",
      "AstraZeneca",
      "Covaxin",
      "Covishield",
      "Moderna",
      "Sputnik V",
    ],
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu1(val) {
      val && setTimeout(() => (this.$refs.picker1.activePicker = "YEAR"));
    },
    "appData.idProofType"() {
      this.appData.passportExpiryDate = "";
    },

    "appData.isPastEvent"() {
      this.appData.pastEvents = [];
      this.appData.pastOthers = "";
    },
  },
  async mounted() {
    // console.log(this.$store.state.eventUrl + "/" + this.$store.state.venueUrl);
    this.isLoading = true;
    const mtemp = await this.$axios.get(
      "/media/onload/" +
        this.$store.state.eventUrl +
        "/" +
        this.$store.state.venueUrl
    );

    this.minitData = await mtemp.data.result;
    // console.log(this.minitData.event_id);
    //console.log(this.minitData);

    if (this.minitData == "") {
      this.minitData = "notfound";
      this.isLoading = false;
      return;
    }

    if (this.minitData.deadline == "close") {
      this.isLoading = false;
      this.$router
        .push(
          "/" +
            this.$store.state.eventUrl +
            "/" +
            this.$store.state.venueUrl +
            "/login"
        )
        .catch(() => {});
      return;
    }

    this.$axios
      .get("/assets/" + this.$store.state.eventUrl + "/tnc/media.html")
      .then((response) => {
        this.tchtml = response.data;
      })
      .catch();

    let d = new Date();
    d.setDate(d.getDate() - 6570);
    this.dob_max = d.toISOString().substr(0, 10);

    let d1 = new Date();
    d1.setDate(d.getDate() - 8570);
    this.expiry_max = d1.toISOString().substr(0, 10);

    this.$axios
      //.get("/media/mediacategory")
      .get("/media/mediaCategoryByEid/" + this.minitData.event_id)
      .then((res) => {
        this.mediaTypeList = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/matchschedule/" + this.minitData.event_id)
      .then((res) => {
        this.currentEvents = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/pastevents")
      .then((res) => {
        this.pastEvents = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/mediarole/" + this.minitData.event_id)
      .then((res) => {
        this.mediaRole = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/mediavenue/" + this.minitData.event_id)
      .then((res) => {
        this.preferedCityList = res.data.result;
      })
      .catch();

    this.countries = country;
    this.countriescode = countrycode;
    this.nationality = nationality;
    this.$store.commit("setPageTitle", "Media Application Form");
    this.$store.commit("setHeadshot", false);
    this.$store.commit("setIdOpen", false);
    this.$store.commit("setIdBackOpen", false);
    this.$store.commit("resetApplication");
    this.isLoading = false;
  },

  methods: {
    selectFileVaccination(file) {
      this.currentFileVaccination = file;
    },
    filterNum: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filterCharNum: function (evt) {
      evt = evt ? evt : window.event;
      if (
        (evt.charCode > 47 && evt.charCode < 58) ||
        (evt.charCode > 64 && evt.charCode < 91) ||
        (evt.charCode > 96 && evt.charCode < 123)
      ) {
        return true;
      } else {
        evt.preventDefault();
      }
    },

    btnviewschedule: function () {
      //alert(this.$apiPathNew + '/assets/' +  this.$store.state.eventUrl + '/tnc/schedule.pdf');

      this.schedulepath =
        this.$apiPathNew +
        "/assets/" +
        this.$store.state.eventUrl +
        "/tnc/schedule.pdf";

      this.dialogpdf = true;
    },

    savedob(date) {
      this.$refs.menu.save(date);
    },
    saveexpirydate(date) {
      this.$refs.menu1.save(date);
    },

    loadHeadShot: function (mode) {
      this.headShotMode = mode;
      if (this.$store.state.isHeadShotOpen == true) {
        this.$store.commit("setHeadshot", false);
      } else {
        this.$store.commit("setHeadshot", true);
      }
    },

    loadIdback() {
      if (this.$store.state.isIdBackOpen == true) {
        this.$store.commit("setIdBackOpen", false);
      } else {
        this.$store.commit("setIdBackOpen", true);
      }
    },
    showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },
    changeCategory: async function (val) {
      await this.$axios
        .get("/media/mediasubcategory/" + val.mc_id)
        .then((res) => {
          this.subCategoryList = res.data.result;
        })
        .catch();
    },
    changePastEvents: function (val) {
      console.log(val);
    },
    gobackSection(val) {
      window.scrollTo(0, 0);
      this.step = val;
    },
    proceed1: async function () {
      if (this.$store.state.imgHeadShot == "") {
        this.showSnackbar("error", "Headshot photo is required!");
        return;
      }
      if (this.$store.state.imgId == "") {
        this.showSnackbar("error", "Id Proof photo is required!");
        return;
      }
      //Check Email Id already Exist
      this.stepLoading = true;
      await this.$axios
        .get(
          "/Media/checkemail/" +
            this.appData.email +
            "/" +
            this.$store.state.eventUrl
        )
        .then((res) => {
          if (res.data.result == "EXISTS") {
            this.showSnackbar("error", "Email ID is already registered.");
          } else {
            this.step = 2;
            window.scrollTo(0, 0);
          }
          this.stepLoading = false;
        });
    },
    proccedPublication: function () {
      if (this.currentFile == "") {
        this.showSnackbar(
          "error",
          "Please upload a Authorised letter from your assigning editor."
        );
        return;
      }
      if (this.appData.email == this.appData.authEmail) {
        this.showSnackbar(
          "error",
          "Applicant and Editor's email id should not same."
        );
        return;
      }
      // this.proceedJob();
      this.step = 4;
      window.scrollTo(0, 0);
    },

    proceedJob: async function () {
      // this.stepLoading = true;
      // alert("ram");
      // console.log(this.appData.roleForEvent);
      //this.appData.roleForEvent = this.appData.roleForEvent;

      //start comment due to confusion
      // await this.$axios
      //   .get(
      //     "/Media/mediastands/" +
      //       this.minitData.event_id +
      //       "/" +
      //       this.appData.roleForEvent
      //   )
      //   .then((res) => {
      //     this.zone = res.data.result;
      //     console.log(this.zone);
      //   });

      //End comment due to confusion

      this.step = 4;
      window.scrollTo(0, 0);
    },

    proccedAddi: function () {
      // if (this.appData.isVaccinated == "Yes") {
      //   if (this.appData.firstDose > this.appData.secondDose) {
      //     this.showSnackbar(
      //       "error",
      //       "Second Dose Date must be later than the First Dose!"
      //     );
      //     this.isLoading = false;
      //     return;
      //   }
      // }
      if (this.currentFileVaccination == "" && this.appData.isVaccinated!='No') {
        this.showSnackbar("error", "Please upload a final vaccination detail.");
        return;
      }

      this.step = 4;
      window.scrollTo(0, 0);
    },

    selectFileLetter(file) {
      this.currentFile = file;
    },
    appSubmit: async function () {
      //  this.appData.xt=this.Appdata.countrycode.dial_code;
      // console.log(this.selRole.role_id);
      if (this.chkverify != true) {
        this.showSnackbar("error", "Please accept Terms & Condition.");
        return;
      }

      this.isLoading = true;

      if (this.$store.state.imgHeadShot.split(",").length > 1) {
        this.appData.photo = this.$store.state.imgHeadShot.split(",")[1];
      } else {
        this.appData.photo = "";
      }
      if (this.$store.state.imgId.split(",").length > 1) {
        this.appData.photoIdFront = this.$store.state.imgId.split(",")[1];
      } else {
        this.appData.photoIdFront = "";
      }
      if (this.$store.state.imgIdBack.split(",").length > 1) {
        this.appData.photoIDBack = this.$store.state.imgIdBack.split(",")[1];
      } else {
        this.appData.photoIDBack = "";
      }

      this.appData.event_id = this.minitData.event_id;
      this.appData.venue_id = this.minitData.venue_id;
      this.appData.mediaType = this.selMediaCategory.mc_id;
      this.appData.mediaType_name = this.selMediaCategory.mc_name;

      // if (this.aclmc != null) {
      //   this.appData.medicalCondition = this.aclmc.toString();
      // }

      // this.appData.selectedMatchs.forEach((el) => {
      //   this.appData.selectedMatch.push(el.s_id);
      // });

      let formData = new FormData();
      formData.append("file", this.currentFile);
      formData.append("vcertificate", this.currentFileVaccination);
      formData.append("tdata", JSON.stringify(this.appData));
      // console.log("New form");
      //console.log(this.appData);

      // , {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }

      await this.$axios.post("/Media", formData).then((res) => {
        // console.log(res.data);
        if (res.data.status == "Success") {
          this.$store.commit("setNewAppId", res.data.result.APPID);
          this.showSnackbar("success", "Application Submitted.");
          // this.$refs.formStep1.reset();
          // this.$refs.formStep2.reset();
          // this.$refs.formStep3.reset();
          //  this.$refs.formStep4.reset();
          //  this.$refs.formStep5.reset();
          this.step = 1;
          this.$router.push(
            "/" +
              this.$store.state.eventUrl +
              "/" +
              this.$store.state.venueUrl +
              "/thankyou"
          );
        } else if (res.data.status == "Failed") {
          if (res.data.message == "ALREADYEXISTS") {
            this.showSnackbar("error", "Applicant already registered!");
          } else {
            this.showSnackbar("error", "Something went wrong!");
          }
        } else {
          // console.log("After1:" + res.data.message);
          this.showSnackbar("error", "Something went wrong 2!");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 720px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 150px !important;
  }
}
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>